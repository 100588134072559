import React from 'react';
import Modal from './Modal';

const ConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <div className="text-center">
        <p>Are you sure you want to delete?</p>
        <button className="bg-red-500 text-white px-4 py-2 mt-4 mr-2 rounded" onClick={onConfirm}>
          Delete
        </button>
        <button className="bg-gray-400 text-white px-4 py-2 mt-4 rounded" onClick={onCancel}>
          Cancel
        </button>
      </div>    
    </Modal>
  );
};

export default ConfirmationModal;
