import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import forexImage from '../../Home/img/forexicon.png'; // replace with your actual path

gsap.registerPlugin(ScrollTrigger);

function ForexSection() {
  const sectionRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    // Animate the entire section
    gsap.fromTo(
      sectionRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1.2, scrollTrigger: sectionRef.current }
    );

    // Animate text
    gsap.fromTo(
      textRef.current,
      { opacity: 0, x: -150 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 80%", // Start animation when text comes into view
          end: "bottom 20%",
          toggleActions: "play none none none"
        }
      }
    );

    // Animate image
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, scale: 0.8 },
      {
        opacity: 1,
        scale: 1,
        duration: 1.7,
        scrollTrigger: {
          trigger: imageRef.current,
          start: "top 80%", // Start when image comes into view
          end: "bottom 20%",
          toggleActions: "play none none none"
        }
      }
    );
  }, []);

  return (
    <div ref={sectionRef} className="flex flex-col md:flex-row items-center justify-between bg-blue-200 p-6 md:p-12 mb-5">
      {/* Left Text Section */}
      <div ref={textRef} className="md:w-1/2 mb-6 md:mb-0">
        <h2 className="text-3xl md:text-4xl font-bold text-blue-900 mb-4">What is forex?</h2>
        <p className="text-lg text-gray-800 mb-6">
          Forex trading is the buying and selling of global currencies. It’s how individuals, businesses, central banks, 
          and governments pay for goods and services in other economies.
        </p>
        <p className="text-lg text-gray-800">
          However, the vast majority of forex trades aren’t for practical purposes. Speculative FX traders seek to profit 
          from fluctuations in the exchange rates between currencies, speculating on whether one will go up or down in 
          value compared to another.
        </p>
        <button className="mt-6 px-6 py-3 bg-blue-900 text-white rounded-full hover:bg-blue-700 transition">
          INTRO TO FOREX
        </button>
      </div>

      {/* Right Image and Info Section */}
      <div className="md:w-1/2 flex flex-col items-center text-white p-6 rounded-lg shadow-lg relative" style={{ backgroundColor: 'rgb(33, 66, 104)' }}>
        <div className="flex flex-col items-center">
          <h3 className="text-4xl font-bold text-white mb-2">FOREX</h3>
          <p className="text-5xl font-bold mb-4">$7.5 trillion</p>
          <p className="text-lg">per day*</p>
        </div>

        {/* Image Container */}
        <div ref={imageRef} className="relative w-full h-48 mt-6">
          <img src={forexImage} alt="Forex Information" className="object-contain w-full h-full" />
        </div>

        <div className="text-center mt-6">
          <p className="text-sm">US stock market $553 billion per month**</p>
          <p className="text-xs mt-2">
            *April 2022 average daily volume from BIS 2022 Triennial FX Report
            <br />
            **August 2022 average notional value from Cboe Global Markets
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForexSection;
