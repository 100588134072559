import { AuthProvider } from './components/auth/AuthContext';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './App.css';
import './styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BotProvider } from './components/BotContext/BotContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
export default function App() {
  const GOOGLE_CLIENT_ID = '257859898388-qnq1om27kqh6g2ts89lcgdeponpp7k7b.apps.googleusercontent.com';
  return (
    
    <BotProvider>
    
    <AuthProvider>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Router />
        </GoogleOAuthProvider>,
        <ToastContainer/>
      </ThemeProvider>
    </AuthProvider>
    </BotProvider>
  );
}
