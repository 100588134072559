import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Home',
    path: '/forexbots',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'statistics',
    path: '/dashboard/user/statistics',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: ' My profile',
    path: '/dashboard/user/profile',
    icon: getIcon('eva:person-fill'),
  },
  {
    title: 'Subscriptions',
    path: '/dashboard/user/subscriptions',
    icon: getIcon('mdi:calendar-month'),
  },
  {
    title: 'Signal Subscriptions',
    path: '/dashboard/user/openaccount',
    icon: getIcon('eva:folder-add-fill'),
  }
];

export default navConfig;
