import React, { useEffect, useState,useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import axios from 'axios';
import styled from 'styled-components';
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
const DropzoneContainer = styled.div`
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
`;
const FileDetails = styled.div`
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
`;

const ProgressBarContainer = styled.div`
  margin-top: 10px;
`;

function ExpertManager() {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    // Fetch the bots data
    axios.get('https://traderz.fund:3060/settings/bots')
      .then(response => {
        setBots(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the bots data!', error);
      });
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setUploadProgress(0); // Reset progress on new file drop
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const handleClose = () => {
    setOpen(false);
    setSelectedSubscription(null);
    setFiles([]);
    setUploadProgress(0);
  };
 const handleOpenDialog= (id) => {
   setSelectedSubscription(id);
   console.log("id",id)
   setOpen(true);
 }


  const handleFileUpload = async () => {
    if (files.length === 0) {
      toast.error('Please select a file to upload.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('No token found. Please log in.');
      return;
    }

    const formData = new FormData();
    formData.append('download_file', files[0]); // Assuming only one file is allowed

    try {
      const response = await fetch(`https://traderz.fund:3060/settings/bots/execution_file/${selectedSubscription}`, {
        method: 'PUT',
        headers: {
          'Authorization': `${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const responseText = await response.text(); // Read the response text for more details
        console.error('Failed to upload file:', responseText);
        throw new Error('Failed to upload file.');
      }

      // Simulate upload progress (as a real-world case might involve progress events)
      setUploadProgress(100);

      toast.success('File uploaded successfully!');
      handleClose();
    } catch (error) {
      console.error('Upload error:', error.message);
      toast.error(error.message);
    }
  };
  return (
   <>
   <div className='contianer d-flex justify-content-center p-5 m-5'>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Bot Name</TableCell>
            <TableCell>Bot ID</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bots.map(bot => (
            <TableRow key={bot.id}>
              <TableCell>{bot.name}</TableCell>
              <TableCell>{bot.id}</TableCell>
              <TableCell>
                <Button variant="contained" className={bot.execution_file?"bg-warning":"bg-primary"} onClick={() => handleOpenDialog(bot.id)}>
                {bot.execution_file?"Update Expert":"Upload Expert"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Upload Expert</DialogTitle> */}
        <DialogContent>
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag & drop some files here, or click to select files</p>
          </DropzoneContainer>
          {files.length > 0 && (
            <FileDetails>
              <p>Selected file: {files[0].name}</p>
            </FileDetails>
          )}
          {uploadProgress > 0 && (
            <ProgressBarContainer>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </ProgressBarContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFileUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      
      </div>
   </>
  );
}

export default ExpertManager;
