import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Box, Typography } from '@mui/material';

function Verify() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [validToken, setValidToken] = useState('');

  useEffect(() => {
    if (token) {
      setValidToken(token);
    } else {
      toast.error('No token found in URL');
    }
  }, [token]);

  const handleVerify = async () => {
    try {
      const response = await axios.post(`https://traderz.fund:3060/users/email-verify/${validToken}`, { token: validToken });
      if (response.status === 200) {
        toast.success('Successfully verified');
        navigate('/login');
      }
    } catch (error) {
      toast.error('Verification failed');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="background.paper"
    >
      <Typography variant="h4" gutterBottom>
        Verify Your Account
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleVerify}
        disabled={!validToken}
        sx={{
          padding: '10px 20px',
          fontSize: '16px',
        }}
      >
        Verify
      </Button>
      <ToastContainer />
    </Box>
  );
}

export default Verify;
