import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Typography } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    text: 'EN',
  },
  {
    value: 'ar',
    label: 'Arabic',
    text: 'AR',
  }
];

// ----------------------------------------------------------------------

export default function LanguagePopover({ onLanguageChange }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(LANGS[0].value);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    if (value) {
      setSelectedLanguage(value);
      localStorage.setItem('selectedLanguage', value);
      onLanguageChange(value);  // Notify the parent component
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          color: 'white', // Set text color to white
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Typography variant="button" sx={{ color: '#ddc767' }}>
          {LANGS.find((lang) => lang.value === selectedLanguage).text}
        </Typography>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === selectedLanguage}
              onClick={() => handleClose(option.value)}
            >
              <Box sx={{ width: 28, mr: 2, textAlign: 'center', color: '#ddc767' }}>
                {option.text}
              </Box>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}