// AddBankModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import { TextField, MenuItem, Select, InputLabel } from '@mui/material';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${(props) => (props.modalOpen ? 'hidden' : 'auto')};
    background: ${(props) => (props.modalOpen ? 'rgba(0, 0, 0, 0.5)' : 'white')};
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px',
    borderRadius: '10px',
    width: '500px',
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const Input = styled.input`
  padding: 12px 15px;
  font-size: 16px;
  border: 1px solid ${(props) => (props.error ? 'red' : '#ccc')};
  border-radius: 6px;
  margin-bottom: 15px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  &:focus {
    border-color: ${(props) => (props.error ? 'red' : '#007bff')};
    outline: none;
    box-shadow: 0 0 5px ${(props) => (props.error ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 123, 255, 0.5)')};
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.disabled ? '#007bff' : '#0056b3')};
    box-shadow: ${(props) => (props.disabled ? 'none' : '0 4px 12px rgba(0, 123, 255, 0.3)')};
  }
  &:focus {
    outline: none;
  }
`;

const CancelButton = styled(Button)`
  background-color: #6c757d;
  &:hover {
    background-color: #5a6268;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
`;

const ErrorText = styled.div`
  color: red;
  margin-bottom: 10px;
`;

const AddBankModal = ({ isOpen, onRequestClose, onSubmit }) => {
  const [newBank, setNewBank] = useState({
    bankName: '',
    bankIban: '',
    bankCountry: '',
    bankSwiftCode: '',
    isHidden: false,
  });
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(isOpen);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBank({ ...newBank, [name]: value });
  };

  const validate = () => {
    let errors = {};
    if (!newBank.bankName.trim()) errors.bankName = 'Bank name is required';
    if (!newBank.bankIban.trim()) errors.bankIban = 'IBAN is required';
    if (!newBank.bankCountry.trim()) errors.bankCountry = 'Country is required';
    if (!newBank.bankSwiftCode.trim()) errors.bankSwiftCode = 'SWIFT Code is required';
    return errors;
  };

  const handleSubmit = (e) => {
   
    const errors = validate();
    if (Object.keys(errors).length === 0) {


       
       
      onSubmit(newBank);
      onRequestClose();
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const isSaveDisabled = Object.values(newBank).some((val) => typeof val === 'string' && val.trim() === '');

  return (
    <>
      <GlobalStyle modalOpen={modalOpen} />
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles} contentLabel="Add Bank Details">
        <Title>Add Bank Details</Title>
        <Input
          type="text"
          name="bankName"
          placeholder="Bank Name"
          value={newBank.bankName}
          onChange={handleChange}
          error={errors.bankName}
        />
        {errors.bankName && <ErrorText>{errors.bankName}</ErrorText>}
        <Input
          type="text"
          name="bankIban"
          placeholder="IBAN"
          value={newBank.bankIban}
          onChange={handleChange}
          error={errors.bankIban}
        />
        {errors.bankIban && <ErrorText>{errors.bankIban}</ErrorText>}
        <Input
          type="text"
          name="bankCountry"
          placeholder="Country"
          value={newBank.bankCountry}
          onChange={handleChange}
          error={errors.bankCountry}
        />
        {errors.bankCountry && <ErrorText>{errors.bankCountry}</ErrorText>}
        <Input
          type="text"
          name="bankSwiftCode"
          placeholder="SWIFT Code"
          value={newBank.bankSwiftCode}
          onChange={handleChange}
          error={errors.bankSwiftCode}
        />
        {errors.bankSwiftCode && <ErrorText>{errors.bankSwiftCode}</ErrorText>}
        <ButtonContainer>
          <CancelButton onClick={onRequestClose}>Cancel</CancelButton>
          <Button onClick={handleSubmit} disabled={isSaveDisabled}>
            Save
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default AddBankModal;
