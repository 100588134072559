import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import config from './config/index.json';

const MainHeroImage = () => {
  const { mainHero } = config;
  const imageRef = useRef(null);

  useEffect(() => {
    // Initial animation for the image when the component mounts
    gsap.from(imageRef.current, {
      opacity: 0,
      y: 40,
      scale: 0.8,
      duration: 1.5,
      ease: "power3.out",
    });

    // Adding a subtle pulse effect to the image
    gsap.to(imageRef.current, {
      scale: 1.02,
      repeat: -1,
      yoyo: true,
      duration: 5,
      ease: 'sine.inOut',
    });
  }, []);

  return (
    <div className="lg:absolute lg:inset-y-2 lg:right-2 lg:w-1/2 lg:p-16 ">
      <img
        ref={imageRef}
        className="h-sm-56 w-full object-contain sm:h-60 md:h-96 lg:w-full lg:h-full p-1"
        src={mainHero.img}
        alt="robot team image"
        onMouseEnter={() => gsap.to(imageRef.current, { scale: 1, rotate: 5, duration: 0.5 })}
        onMouseLeave={() => gsap.to(imageRef.current, { scale: 1, rotate: 0, duration: 0.5 })}
      />
    </div>
  );
};

export default MainHeroImage;
