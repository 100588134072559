import React, { useState } from "react";
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import logo from "../../Home/img/download__1_-removebg-preview.png"; // Adjust path if necessary
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import Iconify from '../../components/Iconify';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'rgb(34, 66, 102);',
  [theme.breakpoints.up('lg')]: {
    width: '100%',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [file, setFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOrderData(null);
    setFile(null);
  };

  const handleSubscriptionCodeChange = (event) => {
    setSubscriptionCode(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleCheckOrder = async () => {
    try {
      const response = await axios.get(`https://traderz.fund:3060/users/getOrder/${subscriptionCode}`);
      const data = response.data;
      setOrderData(data[0] || {});
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const handleUploadAttachment = async () => {
    if (!file || !subscriptionCode) {
      console.error("No file selected or subscription code not provided.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('img', file);

      const response = await axios.put(
        `https://traderz.fund:3060/users/getOrder/${subscriptionCode}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      alert("Attachment uploaded successfully!");
      window.location.reload();
      setFile(null);
    } catch (error) {
      console.error("Error uploading attachment:", error);
      alert("Failed to upload attachment. Please try again.");
    }
  };
const currentPath=window.location.pathname==='/forexbots'
const handleMenuOpen = (event) => {
  setAnchorEl(event.currentTarget);

}
const handleMenuClose = () => {
  setAnchorEl(null);
};
  return (
    <RootStyle>
    
      <ToolbarStyle>
      <Typography variant="h6" component={Link} to="/" className="logo" sx={{ flexGrow: 1 }}>
        <img style={{   height: '47px',width: '214px'}} src={logo} alt="Logo" className="logo-img" />
      </Typography>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
    
       {/*  <Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Toolbar>
      
        <div className="desktop-menu">
          <Button variant="contained" style={{ backgroundColor: '#dbc657', color: '#234264' }} onClick={handleDialogOpen} className="order-button me-5">
          Activate Bot
          </Button>
          <ScrollLink to="products" smooth={true} duration={500} className="nav-link">
            <Button color="inherit" className="nav-link text-white">
            Trading Bots
            </Button>
          </ScrollLink>
          <ScrollLink to="fec" smooth={true} duration={500} className="nav-link">
            <Button color="inherit" className="nav-link text-white">
              FEC
            </Button>
          </ScrollLink>
          <ScrollLink to="Strategy" smooth={true} duration={500} className="nav-link ">
            <Button color="inherit" className="nav-link text-white">
              Strategy
            </Button>
          </ScrollLink>
          <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link text-white">
            <Button style={{ color: 'white' }} color="inherit" className="nav-link">
              Contact Us
            </Button>
          </ScrollLink>
       
        </div>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} className="mobile-menu-button">
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <ScrollLink to="Pricing" smooth={true} duration={500} className="nav-link">
            <MenuItem onClick={handleMenuClose} component={Link} to="/">
              Pricing
            </MenuItem>
          </ScrollLink>
          <ScrollLink to="product" smooth={true} duration={500} className="nav-link">
            <MenuItem onClick={handleMenuClose}>
              Products
            </MenuItem>
          </ScrollLink>
          <ScrollLink to="contactus" smooth={true} duration={500} className="nav-link">
            <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
              Contact Us
            </MenuItem>
          </ScrollLink>
          <MenuItem onClick={handleMenuClose} component={Link} to="/login">
            Login
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/register">
            Join
          </MenuItem>
          <MenuItem onClick={handleDialogOpen}>
            Check My Order
          </MenuItem>
        </Menu>
      </Toolbar>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/*  <LanguagePopover />*/}
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
        sx={{
          width: { xs: '100%', sm: '50%' },
          height: { xs: '100%', sm: 'auto' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        <DialogTitle>Activate Bot</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="subscription-code"
            label="Subscription Code"
            type="text"
            fullWidth
            variant="standard"
            value={subscriptionCode}
            onChange={handleSubscriptionCodeChange}
          />
          {orderData && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData.paymentMethod && (
                    <TableRow>
                      <TableCell>Payment Method</TableCell>
                      <TableCell>{orderData.paymentMethod}</TableCell>
                    </TableRow>
                  )}
                  {orderData.bankName && (
                    <TableRow>
                      <TableCell>Bank Name</TableCell>
                      <TableCell>{orderData.bankName}</TableCell>
                    </TableRow>
                  )}
                  {orderData.bankIban && (
                    <TableRow>
                      <TableCell>Bank IBAN</TableCell>
                      <TableCell>{orderData.bankIban}</TableCell>
                    </TableRow>
                  )}
                  {orderData.bankCountry && (
                    <TableRow>
                      <TableCell>Bank Country</TableCell>
                      <TableCell>{orderData.bankCountry}</TableCell>
                    </TableRow>
                  )}
                  {orderData.bankSwiftCode && (
                    <TableRow>
                      <TableCell>Bank Swift Code</TableCell>
                      <TableCell>{orderData.bankSwiftCode}</TableCell>
                    </TableRow>
                  )}
                  {orderData.paid_price !== undefined && (
                    <TableRow>
                      <TableCell>Paid Price</TableCell>
                      <TableCell>${orderData.paid_price}</TableCell>
                    </TableRow>
                  )}
                  {orderData.paymentStatus && (
                    <TableRow>
                      <TableCell>Payment Status</TableCell>
                      <TableCell>{orderData.paymentStatus}</TableCell>
                    </TableRow>
                  )}
                  {orderData.code && (
                    <TableRow>
                      <TableCell>Order Code</TableCell>
                      <TableCell>{orderData.code}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {file && (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Attachment Preview"
                  style={{ marginTop: '10px', width: '100px', height: '100px', objectFit: 'cover' }}
                />
              )}
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCheckOrder}>Check It!</Button>
          {orderData && (
            <>
              <Button
                onClick={() => document.getElementById('file-input').click()}
                disabled={!orderData}
              >
                <InputAdornment position="end">
                  <AttachFileIcon />
                </InputAdornment>
                Select File
              </Button>
              <input
                type="file"
                id="file-input"
                hidden
                accept="image/*"
                onChange={handleFileChange}
              />
              <Button
                onClick={handleUploadAttachment}
                disabled={!file || !subscriptionCode}
              >
                Submit
              </Button>
            </>
          )}
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
