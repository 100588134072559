import React, { useState } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; 
import axios from 'axios'; // Import axios for API requests
import { useNavigate } from 'react-router-dom'; // React Router for navigation
import './AuthSocial.css';

export default function App() {
  const [userDetails, setUserDetails] = useState({});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const navigate = useNavigate(); // For programmatic navigation

  const responseGoogle = async (response) => {
    if (response.credential) {
      const decoded = jwtDecode(response.credential);
      setUserDetails(decoded);
      setIsUserLoggedIn(true);
      // console.log("Decoded Google user details:", decoded);
      // console.log("Google Token:", response.credential);

      // Send the Google token to your backend API
      try {
        const apiResponse = await axios.get(`https://traderz.fund:3060/users/get_token/${response.credential}`);
        const { token, payload } = apiResponse.data;

        // Destructure the payload for details like email, name, etc.
        const { email, name, email_verified: emailVerified, family_name: familyName, given_name: givenName, hd, picture } = payload;

        // Store token and user details in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('name', name);
        localStorage.setItem('given_name', givenName);
        localStorage.setItem('family_name', familyName);
        localStorage.setItem('hd', hd); // Store hosted domain (if needed)
        localStorage.setItem('picture', picture);

        // Redirect to dashboard if email is verified
        if (emailVerified) {
          navigate('/dashboard/app');
        } else {
          console.error('Email is not verified.');
        }

      } catch (error) {
        console.error('Error sending Google token to backend:', error);
      }
    } else {
      console.error('Google login failed');
    }
  };

  const handleLogout = () => {
    googleLogout(); // Clears the OAuth token and session
    setIsUserLoggedIn(false);
    setUserDetails({});
    localStorage.clear(); // Clear all user details from localStorage on logout
  };

  return (
    <div className="App">
      <br />
      {!isUserLoggedIn && (
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={() => console.error('Google login failed')}
          locale="en" 
          useOneTap={true}
          width={400}
          shape="circle"
          render={(renderProps) => (
            <button
              style={{ minWidth: '350px' }}
              className="google-login-button"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Log in with Google
            </button>
          )}
        />
      )}

     
    </div>
  );
}
