import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserPrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(null);
const navigate=useNavigate()
  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await fetch('https://traderz.fund:3060/users/check-auth', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: ` ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIsAuthenticated(true);

          if (data.status === 'banned') { 
            localStorage.clear();
            toast.error('Your account is banned.');
            navigate('/login');
            setIsAuthenticated(false);
            return;
          }
          if (data.role !== 'user') {
         
            navigate('/dashboard/app');
            // toast.error('You do not have permission to access this page.'); 
          } else {
           
       
          }

       
            setIsAuthorized(true);
         
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
        navigate('/login');
      }
    };

    checkAuth();
  }, []);

  if (isAuthenticated === null || isAuthorized === null) {
    return <div>Loading...</div>; // or a loading spinner
  }

  return isAuthenticated && isAuthorized ? children : <Navigate to="/login" />;
};

export default UserPrivateRoute;
