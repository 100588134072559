import React, { useState, useEffect } from 'react';
import './modal.css';
import Confetti from 'react-confetti';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

const Modal = ({ onClose, isModalOpen }) => {
  const [email, setEmail] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [userCount, setUserCount] = useState(); // Default to 100

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Please enter a valid email address.');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('https://traderz.fund:3060/users/campaign', { email });
      console.log("response", response);
      if (response.data === 'Email Insereted Succefully') {
        setSnackbarSeverity('success');
        setSnackbarMessage('Email submitted successfully! Check your email to follow the steps.');
        onClose();
      } else if (response.status === 409) {
        setSnackbarSeverity('error');
        setSnackbarMessage('User already registered!');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setSnackbarSeverity('error');
        setSnackbarMessage('User already registered!');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to submit email. Please try again later.');
      }
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      setShowConfetti(true);
      const timer = setTimeout(() => setShowConfetti(false), 5000); // Show confetti for 5 seconds

      // Fetch the current user count when the modal opens
      axios.get('https://traderz.fund:3060/users/campaign-counter')
        .then(response => {
          console.log('response', response.data.counter);
          if (response.data.counter) {
            setUserCount(JSON.parse(response.data.counter));
          }
        })
        .catch(error => {
          console.error('Failed to fetch user count:', error);
        });

      return () => clearTimeout(timer);
    }
  }, [isModalOpen]);

  return (
    <div className="modal-overlay">
      {showConfetti && <Confetti />}
      <div className="modal-content">
        <button onClick={onClose} className="modal-close">X</button>
        <img src="./logo.png" alt="Logo" className="modal-logo" />
        <h2 className="modal-title">Congratulations!</h2>
        <p className="modal-message">
          You are one of the first <b className='text-success'>{100-(userCount || 100)}</b> users who will receive a bonus on Signal Subscriptions for forex bots.
        </p>
        <p className="modal-instructions">Follow these steps to activate your bonus:</p>
        <ol className="modal-steps">
          <li className="modal-step-item"><span className="step-number">1.</span> Enter your email below and hit submit.</li>
          <li className="modal-step-item"><span className="step-number">2.</span> You will receive an email containing a Registration link.</li>
          <li className="modal-step-item"><span className="step-number">3.</span> Complete the registration.</li>
          <li className="modal-step-item"><span className="step-number">4.</span> Congratulations!</li>
        </ol>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          className="modal-input"
        />
        <button onClick={handleSubmit} className="modal-submit" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </button>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Modal;
