import React, { createContext, useState } from 'react';

export const BotContext = createContext();

export const BotProvider = ({ children }) => {
  const [selectedBot, setSelectedBot] = useState(null);

  return (
    <BotContext.Provider value={{ selectedBot, setSelectedBot }}>
      {children}
    </BotContext.Provider>
  );
};
