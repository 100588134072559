import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Alert,
  AlertTitle,
} from '@mui/material';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import NavbarComponent from '../components/NewNav';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import axios from 'axios';

const CustomContainer = styled(Container)`
  margin-top: 50px;
    margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    margin-top: 100px;
  }
`;

const PlanDetails = styled(Card)`
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

const BotDetails = styled(Card)`
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
`;

const PaymentMethodButton = styled(Button)`
  margin: 0 10px;
`;

const CopyButton = styled(Button)`
  margin-top: 10px;
`;

const CodeContainer = styled(Alert)`
  margin-top: 20px;
  text-align: center;
`;
const InstructionsContainer = styled(Box)`
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 150px;
`;

const Checkout = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { bot, plan } = state || {};

  const [banks, setBanks] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedWallet, setSelectedWallet] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [code, setCode] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isOrderEnabled, setIsOrderEnabled] = useState(false); // To manage button enable/disable state
  const [loading, setLoading] = useState(true); // For loading state
  const [fetchError, setFetchError] = useState(''); // For fetch error state

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchDetails = async () => {
      console.log('fetching details', token);
      setLoading(true);
      try {
        const bankResponse = await axios.get('https://traderz.fund:3060/settings/all/bank', {});
        const walletResponse = await axios.get('https://traderz.fund:3060/settings/all/wallet', {});

        const bankData = bankResponse.data;
        const walletData = walletResponse.data;
        setBanks(bankData);
        setWallets(walletData);
        setFetchError(''); // Clear error if fetch is successful
      } catch (error) {
        setFetchError('Failed to fetch payment details');
        toast.error('Failed to fetch payment details');
      } finally {
        setLoading(false); // Set loading to false after fetch completes
      }
    };

    fetchDetails();
    generateCode();
  }, []);

  useEffect(() => {
    // Enable or disable the confirm order button based on payment method and selection
    if (paymentMethod === 'bank' && selectedBank) {
      setIsOrderEnabled(true);
    } else if (paymentMethod === 'wallet' && selectedWallet) {
      setIsOrderEnabled(true);
    } else {
      setIsOrderEnabled(false);
    }
  }, [paymentMethod, selectedBank, selectedWallet]);

  if (!bot || !plan) {
    toast.error('No bot or plan data available');
    navigate('/');
    return null;
  }

  const generateCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCode(result);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage('Copied to clipboard');
    setSnackbarOpen(true);
  };

  const handlePurchase = async () => {
    const payload = {
      bot_id: bot.id,
      paymentMethod,
      bankName: paymentMethod === 'bank' ? banks.find((bank) => bank.id === selectedBank)?.bankName || '' : '',
      bankIban: paymentMethod === 'bank' ? banks.find((bank) => bank.id === selectedBank)?.bankIban || '' : '',
      bankCountry: paymentMethod === 'bank' ? banks.find((bank) => bank.id === selectedBank)?.bankCountry || '' : '',
      bankSwiftCode: paymentMethod === 'bank' ? banks.find((bank) => bank.id === selectedBank)?.bankSwiftCode || '' : '',
      walletName: paymentMethod === 'wallet' ? wallets.find((wallet) => wallet.id === selectedWallet)?.walletName || '' : '',
      walletType: paymentMethod === 'wallet' ? wallets.find((wallet) => wallet.id === selectedWallet)?.walletType || '' : '',
      walletValue: paymentMethod === 'wallet' ? wallets.find((wallet) => wallet.id === selectedWallet)?.walletValue || '' : '',
      paid_price: plan.price,
      path_bill: '',
      paymentStatus: 'unpaid',
      code,
      package_id: plan.id,
      token,
    };

    try {
      const response = await fetch('https://traderz.fund:3060/settings/bots/subscribtions/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': ` ${payload.token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 201) {
        toast.success('Purchase successful!');
        setTimeout(() => {
          window.location.href = '/forexbots';
        }, 3000);
      } else {
        toast.error('Failed to complete the purchase');
      }
    } catch (error) {
      toast.error('An error occurred during the purchase');
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePurchaseClick = () => {
    setDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {token ? <DashboardNavbar /> : <NavbarComponent />}
      <CustomContainer
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h2" align="center" gutterBottom>
          Checkout
        </Typography>
        {loading ? (
          <Typography variant="h6" align="center">
            Loading...
          </Typography>
        ) : fetchError ? (
          <Typography variant="h6" align="center" color="error">
            {fetchError}
          </Typography>
        ) : (
          <>
            <PlanDetails>
              <CardContent>
              <Typography variant="h5"> The Selected Bot <b className='text-primary'>{bot.name} Bot</b></Typography>
              <br/>
              Package Selected :<br/>
              <Typography variant="h5">{plan.name}</Typography>
                <Typography variant="h6">${plan.price}</Typography>
                <ul>
                  {plan.Features?.map((feature, index) => (
                    <Typography component="li" key={index}>
                      {feature.name}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
            </PlanDetails>
            <BotDetails>
              {/* <CardContent>
                <Typography variant="h5">{bot.name}</Typography>
                <Typography variant="h6">Strategy: {bot.strategy}</Typography>
                <Typography variant="h6">Specification: {bot.spec}</Typography>
                <Typography variant="h6">Video: {bot.video}</Typography>
              </CardContent> */}
            </BotDetails>
          </>
        )}
        {!loading && !fetchError && (
          <>
            <h4 className="text-center text-primary mt-5 mb-2">Please Choose Your Payment Method</h4>
            <Box textAlign="center" my={2}>
              <PaymentMethodButton
                variant={paymentMethod === 'bank' ? 'contained' : 'outlined'}
                onClick={() => setPaymentMethod('bank')}
              >
                Bank Transfer
              </PaymentMethodButton>
              <PaymentMethodButton
                variant={paymentMethod === 'wallet' ? 'contained' : 'outlined'}
                onClick={() => setPaymentMethod('wallet')}
              >
                Wallet Transfer
              </PaymentMethodButton>
            </Box>
            {paymentMethod && (
              <Box mt={4} width="100%" maxWidth="600px">
                {paymentMethod === 'bank' && (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel>Bank</InputLabel>
                    <Select
                      value={selectedBank}
                      onChange={(e) => setSelectedBank(e.target.value)}
                      label="Bank"
                    >
                      {banks?.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          {bank.bankName}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedBank && (
                      <Card>
                        <CardContent>
                          {banks.filter((bank) => bank.id === selectedBank).map((bank) => (
                            <div key={bank.id}>
                              <Typography><strong>Bank Name:</strong> {bank.bankName}</Typography>
                              <Typography><strong>IBAN:</strong> {bank.bankIban}</Typography>
                              <Typography><strong>Country:</strong> {bank.bankCountry}</Typography>
                              <Typography><strong>Swift Code:</strong> {bank.bankSwiftCode}</Typography>
                              <Tooltip title="Copy Details">
                                <CopyButton
                                  variant="contained"
                                  startIcon={<FileCopyIcon />}
                                  onClick={() => copyToClipboard(`${bank.bankName} ${bank.bankIban}`)}
                                >
                                  Copy Details
                                </CopyButton>
                              </Tooltip>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    )}
                  </FormControl>
                )}
                {paymentMethod === 'wallet' && (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel>Wallet</InputLabel>
                    <Select
                      value={selectedWallet}
                      onChange={(e) => setSelectedWallet(e.target.value)}
                      label="Wallet"
                    >
                      {wallets?.map((wallet) => (
                        <MenuItem key={wallet.id} value={wallet.id}>
                          {wallet.walletName}
                        </MenuItem>
                      ))}
                    </Select>
                    {selectedWallet && (
                      <Card>
                        <CardContent>
                          {wallets.filter((wallet) => wallet.id === selectedWallet).map((wallet) => (
                            <div key={wallet.id}>
                              <Typography><strong>Wallet Name:</strong> {wallet.walletName}</Typography>
                              <Typography><strong>Type:</strong> {wallet.walletType}</Typography>
                              <Typography><strong>Value:</strong> {wallet.walletValue}</Typography>
                              <Tooltip title="Copy Details">
                                <CopyButton
                                  variant="contained"
                                  startIcon={<FileCopyIcon />}
                                  onClick={() => copyToClipboard(`${wallet.walletName} ${wallet.walletValue}`)}
                                >
                                  Copy Details
                                </CopyButton>
                              </Tooltip>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    )}
                  </FormControl>
                )}
                <CodeContainer severity="warning">
                  <AlertTitle className="text-start">Important</AlertTitle>
                  Please include this code in the transfer: {code}
                  <Tooltip title="Copy Code">
                    <IconButton onClick={() => copyToClipboard(code)} color="inherit">
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CodeContainer>
              </Box>
            )}
            <Box textAlign="center" mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePurchaseClick}
                disabled={!isOrderEnabled} // Disable button if not enabled
              >
                Confirm Order
              </Button>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
              <DialogTitle>Confirm Purchase</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to confirm the purchase?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handlePurchase} color="primary">
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
        <InstructionsContainer>
          <Typography variant="h6" gutterBottom>
            Instructions for Activating Your Bot,please follow one of the below procedures:
          </Typography>
          <b className='text-danger'>

          1-contact customer support by phone call.
          </b><br/>
          <b className='text-danger'>2-contact customer support by LiveChat inside our platform</b>
          <h6 className='text-success text-center mt-3 mb-3 font-semibold'>OR</h6>
          <Typography paragraph>
            1. Choose your preferred payment method and complete the payment.
          </Typography>
          <Typography paragraph>
            2. Copy the transaction code provided.
          </Typography>
          <Typography paragraph>
            3. Go to the "Activate Bot" section on your dashboard.
          </Typography>
          <Typography paragraph>
            4. Enter the transaction code and follow the prompts to activate your bot.
          </Typography>
          <Typography paragraph>
            If you encounter any issues, please contact our support team for assistance.
          </Typography>
        </InstructionsContainer>  
      </CustomContainer>
    </>
  );
};

export default Checkout;
