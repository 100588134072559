import { useEffect, useState } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Page from '../components/Page';
import { ProductList } from '../sections/@dashboard/products';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmationModal from './adminpages/modals/ConfirmationModal';

const GetAllProducts = async (setProducts) => {
  try {
    const response = await axios.get('https://traderz.fund:3060/settings/bots');
    setProducts(response.data);
  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

export default function EcommerceShop() {
  const [products, setProducts] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    GetAllProducts(setProducts);
  }, []);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <Page title="Dashboard: Products">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Bots
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <Button type='primary' onClick={() => navigate('/dashboard/newbot')}>Add New Bot</Button>
          </Stack>
        </Stack>
        <ConfirmationModal />

        <ProductList products={products}  GetAllProducts={GetAllProducts}/>
      </Container>
    </Page>
  );
}
