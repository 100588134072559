import React, { useState } from 'react';
import './About.css';

import bot from '../Home/img/qqe3s4dzgt8238iuvvo3.gif';
import logofooter from "../Home/img/download__1_-removebg-preview.png";
const About = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
 <>
 <div style={{ backgroundColor: '#224266' }} className="container-fluid text-white-50 footer pt-5" id="footer">
 <div className="container py-5">
   <div className="row g-5">
  {/* 
    
    
    
      <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
       <a href="/" className="d-inline-block mb-3">
         <img width="170px" src={logofooter} alt="Company Logo" />
       </a>
       <p className="mb-0" id="footerIntro">We offer advanced trading bots for FX and cryptocurrency markets, plus exclusive ICO insights. Our mission is to empower traders with top tools and strategies for success, delivering excellence, reliability, and value.</p>
     </div>
    */}

    <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
    <h5 className="text-white mb-4">Quick links</h5>
    <a className="btn btn-link" href="/">Home</a>
    <a  className="btn btn-link" href="#about" id="footerMenuAbout">About</a>
    <a className="btn btn-link" href="#services" id="footerMenuServices">Services</a>
    <a className="btn btn-link" href="#whyus" id="footerMenuWhyUs">Why Us</a>
    <a className="btn btn-link" href="#terms" id="footerMenuTerms" onClick={openModal}>Terms and Conditions</a>
  </div>


     <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
       <h5 className="text-white mb-4">Get In Touch</h5>
       <div style={{display:'flex' }}>
         <i style={{marginTop:'3px'}} className="fa fa-map-marker-alt me-3" />
         <p>75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ., London, UK</p>
         </div>
       <p><i className="fa fa-phone-alt me-3" />+962795969885</p>
       <p><i className="fa fa-envelope me-3" />support@traderz.fund</p>
       <div className="d-flex pt-2">
       <a  target="_blank" className="btn btn-outline-light btn-social" href="https://x.com/traderz_fund"><i className="fab fa-twitter" /></a>
       <a target="_blank" className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=61563997079027"><i className="fab fa-facebook-f" /></a>
       <a  target="_blank"className="btn btn-outline-light btn-social" href=" https://www.youtube.com/channel/UC_TEObR-3lf6oW_WCZFv5kA"><i className="fab fa-youtube" /></a>
       <a target="_blank" className="btn btn-outline-light btn-social" href="https://www.instagram.com/traderzz_official/?next=%2F"><i className="fab fa-instagram" /></a>
       
     </div>
     </div>
    
     <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
       <h5 className="text-white mb-4">Our Services</h5>
       <a className="btn btn-link" href="#" id="footerLinkFX">FX Trading Bots</a>
       <a className="btn btn-link" href="#" id="footerLinkCrypto">Cryptocurrency Trading Bots</a>
       <a className="btn btn-link" href="#" id="footerLinkICO">Initial Coin Offerings (ICOs)</a>
     </div>
     <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
     <h5 className="text-white mb-4">Popular Links</h5>
     <a className="btn btn-link" href="#about" id="footerLinkAbout">About Us</a>
     <a className="btn btn-link" href="#services" id="footerLinkServices">Services</a>
     <a className="btn btn-link" href="#whyus" id="footerLinkWhyUs">Why Us</a>
   </div>
   </div>
 </div>
 <div className="container wow fadeIn" data-wow-delay="0.1s">
   <div className="copyright">
     <div className="row">
     <div className="col-md-6 text-center text-md-start">
     <div className="col-md-6 col-lg-8 wow fadeIn" data-wow-delay="0.1s">
     <a href="/" className="d-inline-block mb-3">
       <img width="170px" src={logofooter} alt="Company Logo" />
     </a>
     <p className="mb-0" id="footerIntro">We offer advanced trading bots for FX and cryptocurrency markets, plus exclusive ICO insights. Our mission is to empower traders with top tools and strategies for success, delivering excellence, reliability, and value.</p>
   </div>
   </div>

     <div className="col-md-6 text-center text-md-start mb-3 mb-md-0" id="footerCopyright">
     <h1 className='disclamer'>Disclaimer</h1>
     <p>Traderz Fund is a brand owned and operated by Knooz UAB Limited, registered in the UK (Company No. 15489122), London.
     All services and activities under Traderz Fund are managed by Knooz UAB Limited in accordance with UK regulations.
     Investment and trading involve risks, including potential capital loss. Users should seek independent financial advice.
     Traderz Fund and Knooz UAB Limited are not liable for any direct or indirect losses from reliance on our information.</p>
 <br/>
     <h1 className='disclamer'>Risk Disclaimer</h1>
     <p>Trading foreign exchange on margin carries a high level of risk. The high degree of leverage can work against you as well as for you. Carefully consider your investment objectives, experience, and risk appetite. Do not invest money you cannot afford to lose. Seek advice from an independent financial advisor if you have any doubts.</p>
     </div>
     
     </div>
   </div>
 </div>
 <img src={`./qqe3s4dzgt8238iuvvo3.gif`} alt="Bot Animation" />
</div>

{/* Modal Start */}
{isModalOpen && (
<div className="modal-overlay" onClick={closeModal}>
 <div className="modal-content" onClick={(e) => e.stopPropagation()}>
   <span className="close-btn" onClick={closeModal}>&times;</span>
   <h2>Terms and Conditions</h2>
   <div className="modal-body">
     <p>These terms and conditions govern the use of our website, <a style={{color:'#234264'}} href='https://www.traderz.fund'>https://www.traderz.fund</a> from now on
     referred to as the WEBSITE, and the Conditions of the Software License provided from this WEBSITE,
     whose owner is KNOOZ UAB LIMITED, from now on referred to as the COMPANY, with the address: 71-
     75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ.
     Through the WEBSITE, the COMPANY provides the technical information, the functionality, and the
     correct use of the products offered and licensed on it. Therefore, and because of its content and
     purpose, the natural persons or legal entities who make use of the WEBSITE will acquire the status of
     clients and/or users, and thereby they will accept the present terms and conditions of use, the
     conditions of the software license, in the case it is acquired, and privacy policies in force at the moment
     in which they access the WEBSITE.
     In this sense, the natural or legal entities who access the WEBSITE and do not purchase any product shall
     be considered users and accept being subject to the provisions of the present terms and conditions as
     far as these may be applicable.
     Any customer who purchases any product offered on the WEBSITE must be at least 18 years old and
     must complete the requirements in the online forms. Once they are completed and accepted, the
     customer expressly accepts the contents of these terms and conditions, the license acquisition is
     formalized and perfected, and from that moment the COMPANY can proceed to charge the amount of
     money agreed on in the acquisition form and in the way chosen by the customer.
     In this regard, all products purchased must be paid in advance. According to what has been advised
     above, there are several means to make the payment and once this is confirmed, the COMPANY shall
     make available the product for the customer enabling its downloading from the WEBSITE.
     The COMPANY is proud of the systems we have developed and of the quality and performance of our
     products. Nevertheless, the client and/or user should be aware that trading foreign exchange carries a
     high level of risk, and you can lose some or all of your investment. The high degree of leverage that is
     often obtainable in forex trading can work against you as well as for you. The use of leverage can lead to
     large losses as well as large gains. You must be aware of the risks and be willing to accept them to invest
     in the futures and options markets. Do not trade with money you can&#39;t afford to lose.
     Therefore, the client and/or user understands and accepts that THE COMPANY will not be liable or
     responsible for any loss or damage due to any reason. By using any product available on the WEBSITE,
     you acknowledge that you are familiar with these risks and that you are solely responsible for the
     outcomes of your decisions. We accept no liability whatsoever for any direct or consequential loss
     arising from the use of our products. You understand and agree that past results are not necessarily
     indicative of future performance.
     The client and/or user must not use the WEBSITE in any way that causes or may cause, damage to the
     WEBSITE or impairment of the availability or accessibility of the WEBSITE, or in any way that is unlawful,
     illegal, fraudulent, harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or
     activity.

     The client and/or user must not use the WEBSITE to copy, store, host, transmit, send, use, publish, or
     distribute any material that consists of (or is linked to) any spyware, computer virus, Trojan horse,
     worm, keystroke logger, rootkit, or other malicious computer software.
     The client and/or user must not conduct any systematic or automated data collection activities
     (including without limitation scraping, data mining, data extraction, and data harvesting) on or about
     our WEBSITE without our express written consent.
     The client and/or user must not use the WEBSITE to transmit or send unsolicited commercial
     communications. You must not use the WEBSITE for any purposes related to marketing without our
     express written consent.
     The COMPANY does not take responsibility for problems caused by external reasons, like, for example,
     the availability of the web servers, power failures, or the performance of the brokers, on which we have
     no influence. Should financial or personal losses and/or financial damage occur due to such events, we
     cannot be held responsible for them. Despite careful research and thoughtful supply of all the
     information, we are not responsible for any possible failure.
     We take over expressly no responsibility for our products. Should the customer trade using our products
     in any market, e.g., the foreign exchange market, it will be done by his/her own decisions and under
     his/her responsibility. The owner does not take responsibility for possible losses at the foreign exchange
     markets or other investments. With the purchase of any product at the WEBSITE, the customer agrees
     that he was informed by us expressly about the risks. We recommend always testing the product
     beforehand on a demo account.
     Access to certain areas of the WEBSITE is restricted. We reserve the right to restrict access to areas of
     the WEBSITE, or indeed our whole WEBSITE, at our discretion. If we provide you with a private link to
     enable you to access restricted areas of the WEBSITE or other content or services, you must ensure that
     that private link is kept confidential.
     We take your data very seriously. All your data are strictly confidential and may only be treated to
     manage and promote the services and products offered on the WEBSITE. For more information, visit the
     following link: https://www.traderz.fund/Terms/privacy
     All contents on the WEBSITE are protected by international laws regarding intellectual and industrial
     property rights.
     The client and/or user may not modify, copy, reproduce, republish, upload, post, transmit, or distribute,
     in any manner, the material on the WEBSITE, including text, graphics, code, and/or software. You may
     print and download portions of material from the different areas of the WEBSITE solely for your non-
     commercial use provided that you agree not to change or delete any copyright or proprietary notices
     from the materials.
     The customer also has the power to withdraw from the software license acquired formally within seven
     days from the delivery of the product, with prior notification to the COMPANY of this circumstance. The
     COMPANY will refund the total amount of the purchase minus transaction costs. Also, the COMPANY
     reserves the right to ask customers to confirm their refund request. If the customer does not confirm it
     within three calendar days, then the refund request is considered invalid and is annulled. Customers

     who have purchased any given product for the second time after they have already been refunded once
     cannot be refunded again. Since a second purchase of the product is made, we consider that the
     customer is happy with the product.
     The COMPANY can offer discount codes to its customers. If the customer purchases a product using a
     discount code, the above refund policy does not apply, and the sale is final and non-refundable.
     The COMPANY accepts payments in cryptocurrencies, whose value is unstable. If the customer
     purchased a product making payment in cryptocurrency, the above refund policy does not apply, and
     the sale is final and non-refundable. The COMPANY must resort to such measures to avoid being used as
     a counterparty for risk-free shorts: keeping the sale if the price of crypto falls and being asked for a
     refund if the price of crypto rises.
     Alternatively, for a refund, the customer can ask to swap their order for another product of equal or
     similar face value. The customer can switch to another product twice before 90 days have passed since
     the purchase was made, and when he does, the COMPANY understands that the customer is happy with
     his decision and won&#39;t grant further swap requests.
     The following channels are available for the customer to contact the COMPANY: KNOOZ UAB LIMITED
     Email: support@traderz.fund Live chat: Through the WEBSITE
     By purchasing any product, the client understands that reselling or distributing any of our products, for
     commercial or non-commercial purposes, is a fraudulent and illegal violation of the exclusive software
     license agreement, and they infringe the copyright and industrial property items protected by the law
     and which also belong to the COMPANY. Any attempt by the client to do so will terminate the software
     license without prior notice and the possibility of a refund.
     The client and/or user must not use any of our products in any way that causes or may cause, damage to
     the WEBSITE or impairment of the availability or accessibility of the WEBSITE, or in any way that is
     unlawful, illegal, fraudulent, harmful, or in connection with any unlawful, illegal, fraudulent or harmful
     purpose or activity. Any attempt to do so by the customer will cause the software license(s) to be
     terminated without notice and the possibility of a refund.
     By purchasing any product at the WEBSITE and agreeing to these terms, you agree to receive eventual
     communications from the COMPANY via email. This will include, but not necessarily be limited to,
     software updates, confirmations of transactions, purchase statements, financial statements, notices of
     modifications to terms of use, and other basic communications. You agree to advise the COMPANY
     promptly of any errors or omissions in any transaction or the handling of your purchases. Failure to
     provide prompt notice of any errors will be deemed to constitute acceptance of the accuracy of all
     information sent to you.
     The COMPANY reserves the right to update and change the Terms and Conditions from time to time
     without notice. Any new features that augment or enhance the current service, including the release of
     new tools and resources, shall be subject to the terms. Continued use of the WEBSITE and/or guide after
     any such changes shall constitute your consent to such changes.
     The relations established between the owner and customers or users will be governed by the provisions
     in current regulations, applicable legislation, and competent jurisdiction. However, for those cases

     where the regulations provide for the possibility for the parties to submit themselves to a local
     jurisdiction, expressly waiving any other which may be applicable, the parties (owner and customers
     and/or users) accepted their submission to the courts and tribunals of the United Kingdom.</p>
   </div>
 </div>
</div>
)}
{/* Modal End */}
   
 
 </>
  );
};

export default About;
