import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Paper,
  Grid,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog
} from '@mui/material';
import { styled } from '@mui/system';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import axios from 'axios';
const SubscriptionsContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
}));

const SubscriptionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
}));

const SubscriptionDetails = styled(CardContent)(({ theme }) => ({
  flex: '1 0 auto',
}));

const SubscriptionMedia = styled(CardMedia)(({ theme }) => ({
  width: 160,
}));

const GuideSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(4),
  backgroundColor: '#f9f9f9',
}));

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infodialogOpen, setInfoDialogOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem('token');
  const [activeTab, setActiveTab] = useState('rent');
  const [botData, setBotData] = useState(null);

  const handleSaveAccountNumber = async () => {
    if (!accountNumber) {
      setAlertMessage('Account number cannot be empty.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }

    if (!token) {
      setAlertMessage('No token found, please sign in.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }

    setTimeout(async () => {
      try {
        const response = await fetch('https://traderz.fund:3060/users/subscription/updateAccountNumber', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account_number: accountNumber,
            token,
            bot_id: botData
          }),
        });

        if (response) {
          setAlertMessage('Account number saved successfully!');
          setAlertSeverity('success');
          setOpen(true);
          setDialogOpen(false);
        } else {
          setAlertMessage('This bot already exists on your account as a signals subscription, please check your control panel.');
          setAlertSeverity('error');
          setOpen(true);
        }
      } catch (error) {
        setAlertMessage('Error saving account number.');
        setAlertSeverity('error');
        setOpen(true);
      }
    }, 1000);
  };

  const handleEnterLater = () => {
    setDialogOpen(false);
    handleSendRequest();
  };

  const handleSendRequest = async () => {
    if (!token) {
      setAlertMessage('No token found, please sign in.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  const downloadfile = async (botId) => {
    const token = localStorage.getItem('token');
  
    if (!token) {
      setAlertMessage('No token found, please sign in.');
      setAlertSeverity('error');
      setOpen(true);
      return;
    }
  
    try {
      const response = await axios.post(
        'https://traderz.fund:3060/users/my-experts',
        { botId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: ` ${token}`,
          },
        }
      );
  
      const subscription = response.data[0]; // Assuming the response is an array
  
      if (subscription?.execution_file) {
        // If the execution file is present, download it directly
        window.open(subscription.execution_file, '_blank');
      } else {
        // If no execution file, show an error toast
        toast.error('Subscription expired or file not available.');
      }
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      toast.error('An error occurred while downloading the file.');
    }
  };
  
  useEffect(() => {
    const fetchSubscriptions = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch('https://traderz.fund:3060/users/my-subscribtions', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: ` ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setSubscriptions(data);
        } else {
          toast.error('Failed to fetch subscriptions.');
        }
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        toast.error('An error occurred while fetching subscriptions.');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (subscriptions.length === 0) {
    return <Typography variant="h6">No subscriptions found.</Typography>;
  }

  const handleSaveBotId = async (id) => {
    setDialogOpen(true);
    setBotData(id);
  };

  const showPopUpInfo=()=>{
    setInfoDialogOpen(true);
  }


  return (
    <>
      <GuideSection>
        <Typography variant="h5" gutterBottom>
          How to Install a Forex Bot in MT5
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Download the robot file (.ex5) from a trusted source.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Open your MT5 platform and go to File &gt; Open Data Folder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. In the opened folder, navigate to MQL5 &gt; Experts.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. Copy and paste the robot file into the Experts folder.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. Restart your MT5 platform.
        </Typography>
        <Typography variant="body1" gutterBottom>
          6. The robot will now appear in the Navigator tab under the Expert Advisors section.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7. Drag the robot onto the chart you want to trade on.
        </Typography>
        <Typography variant="body1" gutterBottom>
          8. A pop-up window will appear.
        </Typography>
        <Typography variant="body1" gutterBottom>
          9. Allow algo trading and click and adjust Inputs as needed, and press OK.
        </Typography>
        <Typography variant="body1">
          10. The robot will now be active and will start trading according to its strategy.
        </Typography>
      </GuideSection>
      <SubscriptionsContainer maxWidth="md">
        <Typography variant="h4" gutterBottom>
          My Subscriptions
        </Typography>
        {subscriptions&&subscriptions?.map((subscription) => {
            const formattedPaidDate = format(new Date(subscription.paid_date), 'dd/MM/yyyy h:mm a');
            const formattedExpirationDate = format(new Date(subscription.expirationDate), 'dd/MM/yyyy h:mm a');
  return(<>
  
         
          <SubscriptionCard key={subscription.id}>
            {subscription?.Bot?.img&&<SubscriptionMedia
              component="img"
              image={subscription.Bot.img}
              alt={subscription.Bot.name}
            />}
            <SubscriptionDetails>
              <Typography variant="h5">{subscription.Bot.name}{subscription.paid_date!==null&&<b className='h6 ms-auto float-end'>Subscribtion Date</b>}</Typography>
      
              <Typography variant="subtitle1" color="textSecondary">
                {subscription.Package.name} {subscription.paid_date!==null&&<b className='text-sm ms-auto float-end'>{formattedPaidDate} - {formattedExpirationDate} </b>}
              </Typography>
              <Typography
                dangerouslySetInnerHTML={{ __html: subscription.Bot.description.slice(0, 86) + '...' }}
                variant="body1"
                paragraph
              />
              <Divider />
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Payment Method:</strong> {subscription.paymentMethod}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Bank Name:</strong> {subscription.bankName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>IBAN:</strong> {subscription.bankIban}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Bank Country:</strong> {subscription.bankCountry}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>SWIFT Code:</strong> {subscription.bankSwiftCode}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Paid Price:</strong> {subscription.paid_price} USD
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Payment Status:</strong> {subscription.paymentStatus}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Subscription Code:</strong> {subscription.code}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Account Number:</strong> {subscription.account_number ? subscription.account_number : <span className='text-danger'>No Account Number </span>}
                  </Typography>
                </Grid>
              </Grid>
              <Box mt={2} display="flex" gap={2}>
                <Button variant="contained" color="primary" href={subscription.Bot.video} target="_blank">
                  Watch Bot Video
                </Button>
                <Button  variant="contained" color="secondary" onClick={() => downloadfile(subscription.Bot.id)}>
                  Download Expert
                </Button>
                <Button variant="contained" color="secondary"  onClick={() =>
    subscription.account_number
      ? showPopUpInfo(subscription.Bot.id)
      : handleSaveBotId(subscription.Bot.id)
  }>
                  Update Account Number
                </Button>
              </Box>
            </SubscriptionDetails>
          </SubscriptionCard></>)   
       
        })}
      </SubscriptionsContainer>

    

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Enter Account Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="accountNumber"
            label="Account Number"
            type="text"
            fullWidth
            variant="outlined"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEnterLater} color="primary">
            Enter Later
          </Button>
          <Button onClick={handleSaveAccountNumber} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={infodialogOpen} onClose={handleCloseDialog} >
        <DialogTitle> Account Number</DialogTitle>
        <DialogContent className='text-center'>
         <div  role="alert">
        Account number cannot be changed after you have subscribed from User control panel.<br/>
        <b>Please Contact Support To change Account Number</b>
         </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setInfoDialogOpen(false)} color="primary">
            close
          </Button>
        
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Subscriptions;
