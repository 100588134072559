// src/pages/ResetPassword.js

import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Container, Stack, TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('New Password is required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { newPassword: '', confirmPassword: '' },
  });

  const { handleSubmit, register, formState: { errors, isSubmitting } } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`https://traderz.fund:3060/users/reset-password/${token}`, {
        newPassword: data.newPassword,
      });
      toast.success(response.data.message);
      setTimeout(() => {
        navigate('/login'); // Redirect after 3 seconds
      }, 3000);
 
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          boxShadow: 3,
        }}
      >
        <ToastContainer />
        <Typography component="h1" variant="h5" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} noValidate className='mt-3'>
          <Stack spacing={3} sx={{ width: '100%' }}>
            <TextField
              {...register('newPassword')}
              label="New Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!errors.newPassword}
              helperText={errors.newPassword ? errors.newPassword.message : ''}
            />
            <TextField
              {...register('confirmPassword')}
              label="Confirm New Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
            />
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Stack>
        </form>
      </Box>
    </Container>
  );
}
