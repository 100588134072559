import React, { useState, useEffect } from 'react';
import './NewsBar.css'; // Make sure to import the corresponding CSS
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NewsBar = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [newsEvents, setNewsEvents] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  // Fetch economic calendar data
 

  const fetchEconomicData = async () => {
    try {
      const today = new Date();
      const formattedDate = today.toLocaleString('en-US', { month: 'short' }) + ' ' + String(today.getDate()).padStart(2, '0');
      const encodedDate = encodeURIComponent(formattedDate); // Properly encode the formatted date
      const response = await fetch(`https://traderz.fund:5000/api/economic-calendar?date_time=${encodedDate}`);
      const data = await response.json();
      setNewsEvents(data);
    } catch (error) {
      console.error('Error fetching economic data:', error);
    }
  };
  
  
  // Update the clock dynamically
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Fetch economic data on component mount and every 10 minutes
  useEffect(() => {
    fetchEconomicData();
    const interval = setInterval(() => {
      fetchEconomicData();
    }, 600000); // 10 minutes

    return () => clearInterval(interval);
  }, []);

  // Change the displayed news item every 5 seconds
  useEffect(() => {
    if (newsEvents.length > 0) {
      const interval = setInterval(() => {
        setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % newsEvents.length);
      }, 5000); // 5 seconds

      return () => clearInterval(interval);
    }
  }, [newsEvents]);

  return (
    <div className="ticker">
     

      {/* <div className="ticker-title">
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
  <span>Breaking News</span>
</div> */}

      <div className="ticker-news">
        
  <div className="marquee">

    <div className="marquee__item">

      {newsEvents.length > 0 ? (
        newsEvents.map((news, index) => (
          <span key={index} className="news-item">
            <strong>{news.country} ({news.currency})</strong>: {news.event} - {news.impact} impact.
            {news.actual && ` Actual: ${news.actual},`}
            {news.consensus && ` Consensus: ${news.consensus},`}
            {news.previous && ` Previous: ${news.previous}`} |
          </span>
        ))
      ) : (
        <span>Loading economic events...</span>
      )}
    </div>
  </div>
</div>

 <div className="ticker-tip">
        <div className="ticker-tip-info">
          <i className="icon-globe">.</i>
          <span id="clock">{currentTime}   </span><sup><Link to='/forexbots/forex-news' variant="none" className="float-end bg-none  font-semibold text-sm me-5 pt-2 text-danger">Learn More</Link> </sup>
     

          
        </div>
      </div>
    </div>
  );
};

export default NewsBar;
